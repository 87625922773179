<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Send Notification</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field :rules="[rules.required]" v-model="item.title" label="Title" outlined dense
                    placeholder="Title" hide-details></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12">
                  <v-textarea name="input-7-1" label="Description" value="" outlined dense v-model="item.description">
                  </v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-btn @click="submit" :loading="process" color="primary"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
export default {
  name: 'PostAddMulti',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {
        title: null,
        description: null,
      },
      rules: {
        required: value => !!value || 'Required.',
      },
      process: false,
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load(1)
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post('push-notification', formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'dashboard' })
            this.process = true
          })
          .catch(error => {
            this.process = true
            this.$toast.error(error.response.data.message[0])
          })
      }
    },
  },
}
</script>
